import {ButtonProps, createPolymorphicComponent} from '@mantine/core';
import {Button as StyledMantineButton} from '@mantine/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useAssignUserToDepartment} from '../../../../api/tanstack-hooks/team-managment/useAssignUserToDepartment';
import {Button} from '../../../../components/button';
import {StyledModal} from '../../../../components/modal/StyledModal';
import {AssignMemberToTeamModalProps} from './interfaces/AssignMemberToTeamModalProps';

const StyledModalTextWrapper = styled.p`
  color: #5c4d8a;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 150% */
  letter-spacing: 0.15px;
  text-align: center;
`;

const MemberNameHolder = styled.span`
  color: #26145c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.15px;
  margin-right: 5px;
`;

const StyledCanceledButton = styled(StyledMantineButton)`
  background: #dddff8;
  color: #26145c;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.3px;

  &:hover {
    background: #c6c6e3;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
`;

const StyledButton = createPolymorphicComponent<'button', ButtonProps>(
  StyledCanceledButton
);

export const AssignMemberToTeamModal = ({
  opened,
  onClose,
  closeSideDrawer,
  team,
  member,
}: AssignMemberToTeamModalProps) => {
  const {t} = useTranslation('TeamManagement');

  const assignUserToDepartment = useAssignUserToDepartment();

  const handleTransferToDepartment = () => {
    if (!team || !member) return;
    assignUserToDepartment
      .mutateAsync({
        departmentIds: [team?.id],
        userId: member.id,
      })
      .then(() => {
        onClose();
        closeSideDrawer();
      });
  };

  return (
    <StyledModal
      opened={opened}
      onClose={onClose}
      title={t('modal.assignTeamMemberModalTitle')}
      size="md"
    >
      <ModalContentWrapper>
        <div>
          <StyledModalTextWrapper>
            {`${t('modal.assignMessage')} `}
            <MemberNameHolder>{member?.name ?? ''}</MemberNameHolder>
            {`${t('modal.to')} ${team?.name}?`}
          </StyledModalTextWrapper>
        </div>
        <ActionButtonWrapper>
          <StyledButton onClick={onClose}>
            {t('modal.cancelAssign')}
          </StyledButton>
          <Button color="red" onClick={handleTransferToDepartment}>
            {t('modal.confirmAssign')}
          </Button>
        </ActionButtonWrapper>
      </ModalContentWrapper>
    </StyledModal>
  );
};
