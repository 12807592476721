import {createAxiosInstance} from '../../../config';
import {UpdateDepartmentRequest} from '../interfaces/UpdateDepartmentRequest';

const axios = createAxiosInstance();

export const updateTeamName = async (
  departmentId: number | undefined,
  request: UpdateDepartmentRequest
): Promise<void> => {
  const response = await axios.put(`/pc-department/${departmentId}`, request);
  return response.data;
};
