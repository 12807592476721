import React from 'react';

export const CancelChangesIconButton = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52729 3.52925C3.78764 3.2689 4.20975 3.2689 4.4701 3.52925L7.9987 7.05784L11.5273 3.52925C11.7876 3.2689 12.2098 3.2689 12.4701 3.52925C12.7305 3.7896 12.7305 4.21171 12.4701 4.47206L8.94151 8.00065L12.4701 11.5292C12.7305 11.7896 12.7305 12.2117 12.4701 12.4721C12.2098 12.7324 11.7876 12.7324 11.5273 12.4721L7.9987 8.94346L4.4701 12.4721C4.20975 12.7324 3.78764 12.7324 3.52729 12.4721C3.26694 12.2117 3.26694 11.7896 3.52729 11.5292L7.05589 8.00065L3.52729 4.47206C3.26694 4.21171 3.26694 3.7896 3.52729 3.52925Z"
        fill="#3C2C6C"
      />
    </svg>
  );
};
