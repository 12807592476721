import {Breadcrumbs} from '@mantine/core';
import styled from 'styled-components';
import {Color} from '../../../../enums/common';

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding-bottom: 10px;
  color: ${Color.DARK};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  & div {
    margin: 6px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
`;
