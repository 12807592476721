import {Box} from '@mantine/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {BasePage} from '../../components/common';
import {TeamManagementSettings} from './components/TeamManagementSettings/TeamManagementSettings';

export const MainTeamManagementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 30px;
  margin-top: 30px;
`;

export const Title = styled.div`
  color: #26145c;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
`;

export const TeamManagementSettingsWrapper = styled.div`
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(77, 60, 130, 0.2);
`;

export const TeamManagement = () => {
  const {t} = useTranslation('TeamManagement');

  return (
    <BasePage>
      <MainTeamManagementWrapper>
        <ContentWrapper>
          <Box>
            <Title>{t('settings')}</Title>
          </Box>
          <Box>
            <TeamManagementSettingsWrapper>
              <TeamManagementSettings />
            </TeamManagementSettingsWrapper>
          </Box>
        </ContentWrapper>
      </MainTeamManagementWrapper>
    </BasePage>
  );
};
