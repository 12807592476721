import {Color} from '../../../../../enums/common';

export const BreadcrumbsStyle = {
  breadcrumb: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.15px',
    fontWeight: 500,
    color: Color.WHITE,
  },
  separator: {color: Color.WHITE, fontSize: '12px', fontWeight: 500},
};
