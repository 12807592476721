// src/api/config.ts

import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  AxiosHeaders,
} from 'axios';
import {configuration} from '../config';

/**
 * Function to create an Axios instance with an optional baseURL.
 * @param baseURL - The base URL for the Axios instance.
 * @returns Configured Axios instance.
 */
export const createAxiosInstance = (
  baseURL = configuration().baseApiUrl
): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL || 'https://default-api.example.com', // Replace with your default API URL
    // You can add more default configurations here if needed
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
      // Ensure headers are defined
      if (!request.headers) {
        // Initialize headers using AxiosHeaders to ensure type compatibility
        request.headers = new AxiosHeaders();
      }

      // Example: Add an Authorization header if a token exists
      const token = getAuthToken(); // Implement getAuthToken() based on your auth logic
      if (token) {
        request.headers.set('Authorization', `Bearer ${token}`);
      }

      return request;
    },
    (error: AxiosError) => {
      // Handle request errors
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      // Handle response errors
      // You can implement custom error handling logic here
      return Promise.reject(error);
    }
  );

  return instance;
};

/**
 * Placeholder function to retrieve authentication token.
 * Replace this with your actual token retrieval logic.
 */
const getAuthToken = (): string | null => {
  // Example: Retrieve token from localStorage
  return localStorage.getItem('authToken');
};
