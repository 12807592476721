import styled from 'styled-components';

export const Name = styled.div`
  color: #ff7575;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.15px;
`;
