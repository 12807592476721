import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import {Avatar, createStyles, Group, Menu, Space} from '@mantine/core';
import {UserContext} from '../../../contexes';

import {ExpandMenu} from '../../icons/ExpandMenu';

const UserAvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledGroup = styled(Group)`
  cursor: pointer;
`;

const useStyles = createStyles(() => ({
  item: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
  },
  dropdown: {
    marginTop: '23px',
    marginLeft: '-10px',
  },
}));

export const UserAvatar: React.FC = () => {
  const {classes} = useStyles();
  const {t} = useTranslation('Common');
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const hasPrivilegeForTeamManagement = user.authority.find(
    authority => authority === 'manager'
  );

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('table-params');
    window.location.reload();
  };

  return (
    <UserAvatarContainer data-testid="user-avatar">
      <Menu classNames={classes}>
        <Space w="sm" />
        <Menu.Target>
          <StyledGroup>
            <Avatar src={'../../../images/avatar.png'} radius="xl" size="md" />
            <ExpandMenu />
          </StyledGroup>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => navigate('/my-profile')}>
            {t('navbar.myProfile')}
          </Menu.Item>
          <Menu.Divider />
          {hasPrivilegeForTeamManagement && (
            <Menu.Item onClick={() => navigate('/team-managment')}>
              {t('navbar.teamManagement')}
            </Menu.Item>
          )}
          <Menu.Item onClick={logout}>{t('navbar.logout')}</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </UserAvatarContainer>
  );
};
