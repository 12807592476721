import React, {Suspense, useContext, useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import styled from 'styled-components';

import {Loader} from '@mantine/core';

import {UserContext} from 'contexes';

import {useToken} from 'api/tanstack-hooks/auth';
import {TeamManagement} from '../pages/TeamManagement/TeamManagement';

import {ErrorPage} from './ErrorPage';
import {ProtectedRoute} from './ProtectedRoute';

import {ForgotPassword, LoginPage} from '../pages/auth';
import {Dashboard} from '../pages/dashboard/Dashboard';
import {SalesCourses} from '../pages/SalesCourses/SalesCourses';
import {SalesCourseDetails} from '../pages/SalesCourses/SalesCourseDetails';
import {Practices, PracticeV1} from '../pages/practices';
import {ChangePassword} from '../pages/ChangePassword/ChangePassword';
import {MyProfilePage} from '../pages/MyProfile/MyProfilePage';
import {CoursePreview} from '../pages/courses/CoursePreview';
import {Courses} from '../pages/courses/Courses';
import {PracticeV2} from 'pages/practices/PracticeV2';
import PracticeV3 from 'pages/practices/PracticeV3';
import {Course} from 'pages/courses/Course';
import {AssignCourse} from 'pages/manager/AssignCourse';

// Manager routes
import {ManagerCourseDetails} from 'pages/manager/ManagerCourseDetails';
import {Feedback} from 'pages/feedback/Feedback';
import {UserProfile} from 'pages/UserProfile/UserProfile';

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 22em;
`;

const Loading = () => (
  <StyledLoader>
    <Loader size="lg" />
  </StyledLoader>
);

export const Router = () => {
  const [user] = useContext(UserContext);

  const {data: response, error} = useToken(localStorage.getItem('authToken'));

  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (response && response.data) {
      setUserLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (error) {
      setUserLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (!userLoaded) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      {!user.access_token && (
        <Routes>
          {/* Public Routes */}
          <Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>

          {/* Denied Routes */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
      {user.access_token && (
        <Routes>
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Navigate to={'dashboard'} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/practices" element={<Practices />} />
            <Route path="/my-profile" element={<MyProfilePage />} />
            <Route path="/change-password" element={<ChangePassword />} />
            {user.authority.find(authority => authority === 'manager') ? (
              <Route path="/team-managment" element={<TeamManagement />} />
            ) : (
              <Route
                path="/team-managment"
                element={<Navigate to="/denied" replace />}
              />
            )}
            <Route
              path="/courseDetails=:id&userCourseId=:userCourseId"
              element={<CoursePreview />}
            />
            <Route path="/course" element={<Course />} />
            <Route
              path="/salesCourseDetails=:id&userCourseId=:userCourseId&isLeadership=:isLeadership"
              element={<SalesCourseDetails />}
            />
            <Route
              path="/practices/v3/userExerciseId=:userExerciseId"
              element={<PracticeV3 />}
            />
            <Route
              path="/practices/v2/rasaBotKey=:rasaBotKey&userExerciseId=:userExerciseId"
              element={<PracticeV2 />}
            />
            <Route path="/practices/:userExerciseId" element={<PracticeV1 />} />

            {/* Manager Routes */}
            <Route path="/manager/salesCourses" element={<SalesCourses />} />
            <Route
              path="/manager/course/:courseId/details"
              element={<ManagerCourseDetails />}
            />
            <Route
              path="/manager/course/:courseId/assign"
              element={<AssignCourse />}
            />
            <Route
              path="/dashboard/feedback/userId/:userId"
              element={<Feedback />}
            />

            <Route
              path="dashboard/userProfile/userId/:userId"
              element={<UserProfile />}
            />

            <Route path="/login" element={<Navigate to="/" replace />} />
            {/*<Route path="*" element={<Navigate to="/denied" replace />} />*/}
          </Route>

          {/* Denied Routes */}
          <Route path="/denied" element={<ErrorPage />} />
        </Routes>
      )}
    </Suspense>
  );
};
