import React, {createContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSearchParams} from 'react-router-dom';

import {BasePage} from 'components/common';
import {CourseContent} from 'components/courses/common/CourseContent/CourseContent';

import {
  useGetCourseChapters,
  useGetUserCourseDetails,
  useStartCourse,
} from 'api/tanstack-hooks/courses';
import {CourseSidebar} from 'components/courses/common/course-preview';
import {ConversationTranscription} from '../../components/conversation-transcription/ConversationTranscription';

const CourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f8;
  min-height: calc(100vh - 80px);
`;

const MainContentContainer = styled.div`
  margin: 20px auto;
  width: 60%;
`;

export const CourseContext = createContext<{
  setShowConversationTranscription?: (toggle: boolean) => void;
}>({});

export const Course: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isLeaderShipCourse = searchParams.get('isLeadership') === 'true';

  const userCourseId = Number(searchParams.get('userCourseId'));

  const {data: courseDetails} = useGetUserCourseDetails(userCourseId);
  const {data: courseChapters, refetch} = useGetCourseChapters(userCourseId);
  const startCourseMutation = useStartCourse();
  const [showConversationTranscription, setShowConversationTranscription] =
    useState<boolean>(false);

  const overviewUwl = isLeaderShipCourse
    ? `/salesCourseDetails=${courseDetails?.data?.courseId}&userCourseId=${userCourseId}&isLeadership=true`
    : `/courseDetails=${courseDetails?.data?.courseId}&userCourseId=${courseDetails?.data?.userCourseId}`;

  useEffect(() => {
    startCourseMutation.mutate(Number(userCourseId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CourseContext.Provider value={{setShowConversationTranscription}}>
      <BasePage>
        {courseDetails && courseChapters && (
          <CourseContainer>
            <MainContentContainer>
              <CourseContent
                chapters={courseChapters.data}
                course={courseDetails.data}
                refetch={refetch}
              />
            </MainContentContainer>
            {showConversationTranscription ? (
              <ConversationTranscription />
            ) : (
              <CourseSidebar
                overviewUrl={overviewUwl}
                chapters={courseChapters.data}
                allowSelection={true}
                isLeadershipCourse={isLeaderShipCourse}
              />
            )}
          </CourseContainer>
        )}
      </BasePage>
    </CourseContext.Provider>
  );
};
