import styled from 'styled-components';

export const Department = styled.div`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
  display: flex;
  gap: 10px;
`;
