import React from 'react';

export const UnassignActionIconButton = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 18H2.25C2.95538 14.238 5.2934 12.2174 9.26405 11.9383M14.7365 5.99735C14.3257 3.71921 12.4732 2 10.25 2C7.72527 2 5.67857 4.21726 5.67857 6.95238C5.67857 9.35468 7.25746 11.3575 9.35238 11.8093M12.9167 11.9048H18.25"
        stroke="#8F87A8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
