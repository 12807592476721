import styled from 'styled-components';
import {Color} from '../../../../enums/common';

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;

  input {
    color: ${Color.DARK};
  }
`;
