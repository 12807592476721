import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Visualizer, VisualizerConfig} from './audioVisualizer';
import {useAudioPlayerStore} from 'store/AudioPlayerStore';

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AudioPlayer: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const visualizerRef = useRef<Visualizer | null>(null);

  const audioSrc = useAudioPlayerStore(state => state.audioSrc);

  useEffect(() => {
    if (audioSrc && canvasRef.current) {
      const canvasElement = canvasRef.current;

      const audioElement = new Audio(audioSrc);
      audioElement.crossOrigin = 'anonymous';
      audioElement.muted = true; //double play back report flag

      if (visualizerRef.current) {
        visualizerRef.current.cleanup();
      }

      const visualizerConfig: VisualizerConfig = {
        audioElement,
        canvasElement,
        style: 'line',
        barWidth: 5,
        barHeight: 2,
        barSpacing: 10,
        barColor: '#349d8e',
        shadowBlur: 20,
        shadowColor: '#ffffff',
      };

      visualizerRef.current = new Visualizer(visualizerConfig);

      audioElement.play();
    }
    //cleanup on unmount ?Dimitar check and remove console logs
    return () => {
      if (visualizerRef.current) {
        visualizerRef.current.cleanup();
      }
    };
  }, [audioSrc]);

  return (
    <CenteredWrapper>
      <canvas ref={canvasRef} width={200} height={25}></canvas>
    </CenteredWrapper>
  );
};

export default AudioPlayer;
