import styled from 'styled-components';

export const ChartTitle = styled.div`
  color: #1e1b39;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.15px;
`;
