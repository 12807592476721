import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {SortingField} from '../../../pages/TeamManagement/sorting/SortingField';
import {SortingType} from '../../../pages/TeamManagement/sorting/SortingType';
import {getDepartmentsForOrganization} from './fetches/getDepartmentsForOrganization';

export const useDepartmentsForOrganization = (id: number) => {
  const queryClient = useQueryClient();

  const query = useQuery(
    ['departmentsForOrganization', id],
    () => getDepartmentsForOrganization(id),
    {
      enabled: !!id,
    }
  );

  const sortMutation = useMutation(
    ({
      sortField,
      sortType,
    }: {
      sortField?: SortingField;
      sortType?: SortingType;
    }) => getDepartmentsForOrganization(id, sortField, sortType),
    {
      onSuccess: data => {
        queryClient.setQueryData(['departmentsForOrganization', id], data);
      },
    }
  );

  return {...query, sortDepartments: sortMutation.mutate};
};
