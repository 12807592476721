import styled from 'styled-components';

export const Level = styled.div`
  height: 20px;
  padding: 0 12px;
  border-radius: 25px;
  width: max-content;
  background-color: #5c4d8a;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
