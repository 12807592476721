import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

// Containers
import ChatBotV3Container from 'containers/chatbots/ChatBotV3Container';

// Components
import {BasePage} from 'components/common';
import styled from 'styled-components';
import {Exercise} from 'types/chapters/chapters';

// Services
import {getExercise, startExercise} from 'api/practices';
import {ConversationTranscription} from '../../components/conversation-transcription/ConversationTranscription';

const TranscriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f8;
  min-height: calc(100vh - 80px);
`;

export interface StartExerciseInterface {
  conversationId: number;
  description: string;
}

const PracticeV3 = () => {
  const {userExerciseId} = useParams();
  const [exercise, setExercise] = useState<Exercise | null>();
  const [conversationId, setConversationId] = useState(0);
  const [showConversationTranscription, setShowConversationTranscription] =
    useState<boolean>(false);

  const handleShowingConversationTranscription = () => {
    setShowConversationTranscription(true);
  };

  useEffect(() => {
    const handleInitConversation = async () => {
      if (!userExerciseId) return;

      const exercise = await getExercise(+userExerciseId);

      const res = await startExercise(+userExerciseId);
      const data = res.data as unknown as StartExerciseInterface;

      exercise.data.description = data.description;

      setConversationId(data.conversationId as unknown as number);

      setExercise(exercise.data as unknown as Exercise);
    };

    handleInitConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BasePage>
      {exercise && userExerciseId && (
        <TranscriptionContainer>
          <ChatBotV3Container
            userExerciseId={+userExerciseId}
            hideBreadCrumbs={false}
            exercise={exercise}
            conversationId={conversationId}
            handleShowingConversationTranscription={
              handleShowingConversationTranscription
            }
          />
          {showConversationTranscription && <ConversationTranscription />}
        </TranscriptionContainer>
      )}
    </BasePage>
  );
};

export default PracticeV3;
