import {ConversationTranscription} from '../../enums/conversation-transcription/ConversationTranscription';
import {ConversationResponse} from '../../types/conversation-transcription/ConversationResponse';
import {createAxiosInstance} from '../config';

const axios = createAxiosInstance();

export const getConversationTranscription = async (conversationId: number) => {
  const {data} = await axios.get<ConversationResponse[]>(
    `/${ConversationTranscription.GET_CONVERSATION_CONTENT}/${conversationId}`
  );
  return data;
};
