import styled from 'styled-components';
import {Color} from '../../../../enums/common';

export const TableTitle = styled.div`
  color: ${Color.DARK};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 0;
`;
