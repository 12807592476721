import styled from 'styled-components';
import {Color} from '../../../../enums/common';

export const ChartFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  color: ${Color.DARK};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
