import {ActionIcon, Avatar, Table, TextInput} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {TeamMemberResponse} from '../../../../api/tanstack-hooks/team-managment/interfaces/TeamMemberResponse';
import {useTeamMembersForDepartment} from '../../../../api/tanstack-hooks/team-managment/useTeamMembersForDepartment';
import {useUpdateTeamName} from '../../../../api/tanstack-hooks/team-managment/useUpdateTeamName';
import {capitalizeFirstLetter} from '../../../../utils/common';
import {SortingField} from '../../sorting/SortingField';
import {SortingType} from '../../sorting/SortingType';
import {TableWrapper} from '../TableWrapper/TableWrapper';
import {AscendingSortingIconButton} from '../TeamManagementSettings/icon-button-components/AscendingSortingIconButton';
import {CancelChangesIconButton} from '../TeamManagementSettings/icon-button-components/CancelChangesIconButton';
import {DescendingSortingIconButton} from '../TeamManagementSettings/icon-button-components/DescendingSortingIconButton';
import {EditMemberIconButton} from '../TeamManagementSettings/icon-button-components/EditMemberIconButton';
import {EditTeamNameIconButton} from '../TeamManagementSettings/icon-button-components/EditTeamNameIconButton';
import {NavigateToTeamsIconButton} from '../TeamManagementSettings/icon-button-components/NavigateToTeamsIconButton';
import {SaveChangesIconButton} from '../TeamManagementSettings/icon-button-components/SaveChangesIconButton';
import {UnsortedIconButton} from '../TeamManagementSettings/icon-button-components/UnsortedIconButton';
import {TeamMemberActionPanel} from '../TeamMemberActionPanel/TeamMemberActionPanel';
import {TeamMemberOverviewProps} from './interfaces/TeamMemberOverviewProps';

const TableHeader = styled.th`
  background-color: #f1f0f4;
  padding: 10px;
  text-align: left;
  color: #26145c !important;
  position: sticky;
  top: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.15px;
  z-index: 1;
`;

const TableCell = styled.td`
  color: #5c4d8a;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
`;

const ActionsTableCell = styled.div`
  padding: 10px;
  display: flex;
  justify-content: end;
`;

const TeamActionsWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 0.5rem;
  align-items: center;
  padding: 15px 0 15px 0;
`;

const TeamNameHolder = styled.span`
  color: #26145c;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
`;

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
`;

const StyledInput = styled(TextInput).attrs(() => ({
  styles: {
    input: {
      color: '#26145C',
      backgroundColor: '#f6f4fc',
      border: 'none',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
  },
}))``;

const EditingActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TeamMemberOverviewComponent = ({
  team,
  goBackToDepartments,
  teams,
}: TeamMemberOverviewProps) => {
  const {t} = useTranslation('TeamManagement');
  const [isEditTitleMode, setIsEditTitleMode] = useState<boolean>(false);
  const [newTeamName, setNewTeamName] = useState<string>(team?.name ?? '');
  const [selectedMember, setSelectedMember] =
    useState<TeamMemberResponse | null>(null);
  const [opened, {open, close}] = useDisclosure(false);

  const [sortField, setSortField] = useState<SortingField>(null);
  const [sortState, setSortState] = useState<SortingType>('UNSORTED');

  const {data, sortTeamMembers} = useTeamMembersForDepartment(team?.id);

  const updateTeamNameMutation = useUpdateTeamName();

  const handleResetSelectMemberFromSidePanel = () => {
    setSelectedMember(null);
  };

  const handleUpdateTeam = () => {
    const departmentId = team?.id;
    const request = {name: newTeamName};

    updateTeamNameMutation.mutate({departmentId, request});
    handleEditing();
  };

  const openDrawer = (member: TeamMemberResponse) => {
    setSelectedMember(member);
    open();
  };

  const renderAvatarWithInitials = (fullName: string) => {
    const initials = fullName
      .split(' ')
      .map(word => word[0]?.toUpperCase())
      .join('')
      .slice(0, 2);
    return (
      <Avatar radius="xl" color="blue">
        {initials}
      </Avatar>
    );
  };

  const handleEditing = () => {
    setIsEditTitleMode(!isEditTitleMode);
  };

  const handleCancelEditing = () => {
    setIsEditTitleMode(!isEditTitleMode);
    setNewTeamName(team?.name ?? '');
  };

  const handleGoBack = () => {
    goBackToDepartments();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTeamName(event.target.value);
  };

  const toggleSortState = (field: SortingField) => {
    setSortField(prevField => {
      const isSameField = prevField === field;
      const newState = isSameField
        ? (prevState => {
            switch (prevState) {
              case 'UNSORTED':
                return 'ASC';
              case 'ASC':
                return 'DESC';
              case 'DESC':
                return 'UNSORTED';
              default:
                return 'UNSORTED';
            }
          })(sortState)
        : 'ASC';
      setSortState(newState);

      sortTeamMembers({
        sortField: field,
        sortType: newState,
      });
      return field;
    });
  };

  const getSortingIcon = (field: SortingField) => {
    if (sortField !== field || sortState === 'UNSORTED')
      return <UnsortedIconButton />;
    return sortState === 'ASC' ? (
      <AscendingSortingIconButton />
    ) : (
      <DescendingSortingIconButton />
    );
  };

  return (
    <>
      {!isEditTitleMode && (
        <TeamActionsWrapper>
          <ActionIcon onClick={handleGoBack}>
            <NavigateToTeamsIconButton />
          </ActionIcon>
          <TeamNameHolder>{newTeamName}</TeamNameHolder>
          <ActionIcon onClick={handleEditing}>
            <EditTeamNameIconButton />
          </ActionIcon>
        </TeamActionsWrapper>
      )}
      {isEditTitleMode && (
        <TeamActionsWrapper>
          <ActionIcon onClick={handleGoBack}>
            <NavigateToTeamsIconButton />
          </ActionIcon>
          <StyledInput value={newTeamName} onChange={handleInputChange} />
          <EditingActionsWrapper>
            <ActionIcon onClick={handleUpdateTeam}>
              <SaveChangesIconButton />
            </ActionIcon>
            <ActionIcon onClick={handleCancelEditing}>
              <CancelChangesIconButton />
            </ActionIcon>
          </EditingActionsWrapper>
        </TeamActionsWrapper>
      )}
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <TableHeader>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  {t('table.teamMember')}
                  <ActionIcon onClick={() => toggleSortState('name')}>
                    {getSortingIcon('name')}
                  </ActionIcon>
                </div>
              </TableHeader>
              <TableHeader>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  {t('table.level')}
                  <ActionIcon onClick={() => toggleSortState('level')}>
                    {getSortingIcon('level')}
                  </ActionIcon>
                </div>
              </TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {data?.map((member, index) => (
              <tr key={index}>
                <TableCell>
                  <AvatarWrapper>
                    {renderAvatarWithInitials(member.name)}
                    {member.name}
                  </AvatarWrapper>
                </TableCell>
                <TableCell>{capitalizeFirstLetter(member.level)}</TableCell>
                <TableCell>
                  <ActionsTableCell>
                    <ActionIcon onClick={() => openDrawer(member)}>
                      <EditMemberIconButton />
                    </ActionIcon>
                  </ActionsTableCell>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      <TeamMemberActionPanel
        handleResetSelectMemberFromSidePanel={
          handleResetSelectMemberFromSidePanel
        }
        team={team}
        teams={teams?.filter(team => team.id !== selectedMember?.departmentId)}
        opened={opened}
        close={close}
        member={selectedMember}
      />
    </>
  );
};
