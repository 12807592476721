import {showNotification} from '@mantine/notifications';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {unassignTeamMemberFromTeam} from './fetches/unassignTeamMemberFromTeam';

export const useUnassignTeamMemberMutation = () => {
  const queryClient = useQueryClient();
  const {t} = useTranslation('TeamManagement');

  return useMutation(
    (userDepartmentId: number) => unassignTeamMemberFromTeam(userDepartmentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['teamMembers']);
        queryClient.invalidateQueries(['departmentsForOrganization']);
        showNotification({
          title: t('drawer.success'),
          message: t('alerts.successUserUnassign'),
          color: 'green',
        });
      },
      onError: () => {
        showNotification({
          title: t('drawer.error'),
          message: t('alerts.errorUserUnassign'),
          color: 'red',
        });
      },
    }
  );
};
