import styled from 'styled-components';

export const Subtitle = styled.div`
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15px;
`;
