import {showNotification} from '@mantine/notifications';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {assignTeamMemberFromTeam} from './fetches/assignUserToDepartment';
import {AssignUserToDepartmentRequest} from './interfaces/AssignUserToDepartmentRequest';

export const useAssignUserToDepartment = () => {
  const queryClient = useQueryClient();
  const {t} = useTranslation('TeamManagement');
  return useMutation(
    (assignUserToDepartmentPayload: AssignUserToDepartmentRequest) =>
      assignTeamMemberFromTeam(assignUserToDepartmentPayload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['teamMembers']);
        queryClient.invalidateQueries(['departmentsForOrganization']);
        showNotification({
          title: t('drawer.success'),
          message: t('alerts.successUserAssign'),
          color: 'green',
        });
      },
      onError: () => {
        showNotification({
          title: t('drawer.error'),
          message: t('alerts.errorUserAssign'),
          color: 'red',
        });
      },
    }
  );
};
