import styled from 'styled-components';

export const Title = styled.div`
  color: #fa5f4f;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
`;
