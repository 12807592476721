import {SortingField} from '../../../../pages/TeamManagement/sorting/SortingField';
import {SortingType} from '../../../../pages/TeamManagement/sorting/SortingType';
import {createAxiosInstance} from '../../../config';
import {DepartmentsResponse} from '../interfaces/DepartmentsResponse';

const axios = createAxiosInstance();

export const getDepartmentsForOrganization = async (
  id: number,
  sortField?: SortingField,
  sortType?: SortingType
): Promise<DepartmentsResponse[]> => {
  const sortPath =
    sortField && sortType !== 'UNSORTED'
      ? `?sortKey=${sortField}/${sortType}`
      : '';
  const response = await axios.get(
    `/pc-organization/${id}/departments${sortPath}`
  );
  return response.data.data;
};
