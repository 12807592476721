import React, {FC, ReactNode} from 'react';
import {Card, Group, Text} from '@mantine/core';
import styled from 'styled-components';
import {Color} from 'enums/common';

interface MetricCardProps {
  title: string;
  icon?: ReactNode;
  metricValue?: string | number | ReactNode;
  children?: ReactNode;
  backgroundColor?: string;
}

const Title = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${Color.DARK};
`;

const Value = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: ${Color.DARK};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.DARK};
`;

export const MetricCard: FC<MetricCardProps> = ({
  icon,
  title,
  metricValue,
  children,
  backgroundColor = '',
}) => {
  return (
    <Card
      withBorder
      style={{
        minHeight: 126,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 4px 0px rgba(77, 60, 130, 0.20)',
        ...(backgroundColor && {backgroundColor}),
      }}
    >
      <Group
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Title>{title}</Title>
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </Group>
      {metricValue && <Value>{metricValue}</Value>}

      {children}
    </Card>
  );
};
