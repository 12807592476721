import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {OrganizationsResponse} from '../../../../api/tanstack-hooks/team-managment/interfaces/OrganizationsResponse';
import {useOrganizationsOverview} from '../../../../api/tanstack-hooks/team-managment/useOrganizationsOverview';
import {TeamsOverviewComponent} from '../TeamsOverviewComponent/TeamsOverviewComponent';

const MainWrapper = styled.div`
  display: flex;
  height: 500px;
  gap: 1rem;
`;

const LeftOrganizationsPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 100%;
  border-right: 1px solid rgba(77, 60, 130, 0.2);
`;

const ListOfOrganizationsTitleWrapper = styled.div`
  padding: 15px;
  background: #fff;
  border-bottom: 1px solid #f6f4fc;
`;

const ListOfOrganizationsTitleHolder = styled.span`
  color: #26145c;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

const ListOfOrganizationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const OrganizationsHolderWrapper = styled.span<{isActive: boolean}>`
  padding: 15px 20px;
  border-bottom: 1px solid #f6f4fc;
  background-color: ${({isActive}) => (isActive ? '#F6F4FC' : 'transparent')};
  color: #26145c;
  font-weight: ${({isActive}) => (isActive ? 700 : 400)};

  &:hover {
    background-color: #f6f4fc;
    cursor: pointer;
  }
`;

const RightOrganizationsPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 850px;
  gap: 1rem;
  padding-right: 1.5rem;
  border-right: 1px solid rgba(77, 60, 130, 0.2);
  padding-bottom: 30px;
  height: 100%;
`;

export const TeamManagementSettings = () => {
  const [activeOrganization, setActiveOrganization] =
    useState<OrganizationsResponse | null>(null);

  const handleOrganizationSelect = (organization: OrganizationsResponse) => {
    setActiveOrganization(organization);
  };

  const {t} = useTranslation('TeamManagement');

  const {data} = useOrganizationsOverview();

  return (
    <MainWrapper>
      <LeftOrganizationsPaneWrapper>
        <ListOfOrganizationsTitleWrapper>
          <ListOfOrganizationsTitleHolder>
            {t('listOfOrganizations')}
          </ListOfOrganizationsTitleHolder>
        </ListOfOrganizationsTitleWrapper>
        <ListOfOrganizationsWrapper>
          {data?.map((organization, index) => (
            <OrganizationsHolderWrapper
              key={index}
              isActive={activeOrganization === organization}
              onClick={() => handleOrganizationSelect(organization)}
            >
              {organization.name}
            </OrganizationsHolderWrapper>
          ))}
        </ListOfOrganizationsWrapper>
      </LeftOrganizationsPaneWrapper>
      <RightOrganizationsPaneWrapper>
        {activeOrganization !== null && (
          <TeamsOverviewComponent organization={activeOrganization} />
        )}
      </RightOrganizationsPaneWrapper>
    </MainWrapper>
  );
};
