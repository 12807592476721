import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {SortingField} from '../../../pages/TeamManagement/sorting/SortingField';
import {SortingType} from '../../../pages/TeamManagement/sorting/SortingType';
import {getTeamMembersForDepartment} from './fetches/getTeamMembersForDepartment';

export const useTeamMembersForDepartment = (
  departmentId: number | undefined
) => {
  const queryClient = useQueryClient();

  const query = useQuery(
    ['teamMembers', departmentId],
    () => getTeamMembersForDepartment(departmentId),
    {
      enabled: !!departmentId,
    }
  );

  const sortMutation = useMutation(
    (params: {sortField?: SortingField; sortType?: SortingType}) =>
      getTeamMembersForDepartment(
        departmentId,
        params.sortField,
        params.sortType
      ),
    {
      onSuccess: data => {
        queryClient.setQueryData(['teamMembers', departmentId], data);
      },
    }
  );

  return {
    ...query,
    sortTeamMembers: sortMutation.mutate,
    isSorting: sortMutation.isLoading,
  };
};
