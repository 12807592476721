import React from 'react';

export const SaveChangesIconButton = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.806 4.19526C14.0664 4.45561 14.0664 4.87772 13.806 5.13807L7.13937 11.8047C6.87902 12.0651 6.45691 12.0651 6.19656 11.8047L2.86323 8.4714C2.60288 8.21106 2.60288 7.78894 2.86323 7.5286C3.12358 7.26825 3.54569 7.26825 3.80604 7.5286L6.66797 10.3905L12.8632 4.19526C13.1236 3.93491 13.5457 3.93491 13.806 4.19526Z"
        fill="#3C2C6C"
      />
    </svg>
  );
};
