import {createStyles} from '@mantine/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useGetConversationTranscription} from '../../api/tanstack-hooks/conversation-transcription/useGetConversationTranscription';
import {useChatBotV3Store} from '../../store/ChatBotV3store';
import {CollapseButton} from '../common';

const useSidebarStyles = createStyles({
  closed: {
    width: '4em',
    transitionDuration: '600ms',
  },
  open: {
    width: '32%',
    transitionDuration: '600ms',
  },
  transform: {
    transform: 'rotate(180deg)',
  },
});

const TranscriptionSidebarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 0 #4d3c8233;
`;

const StyledCollapseButton = styled(CollapseButton)`
  position: absolute;
  top: 24px;
  left: -18px;
`;

const SidebarContent = styled.div`
  width: 80%;
  margin: 56px auto 0 auto;
`;

const TitleWrapper = styled.div`
  color: #131259;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 0.15px;
  border-bottom: #26145c1f solid 1px;
  padding: 10px;
`;

const MessagesContainer = styled.div`
  height: 75vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 25px;
`;

const MessageSenderWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const MessageContent = styled.span`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const MessageSender = styled.span<{color: string; backgroundColor: string}>`
  color: ${({color}) => color};
  background: ${({backgroundColor}) => backgroundColor};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  padding: 4px;
`;

export const ConversationTranscription = () => {
  const {classes, cx} = useSidebarStyles();
  const [isOpen, setIsOpen] = useState(true);
  const {t} = useTranslation('ConversationTranscription');

  const _conversationId = useChatBotV3Store(state => state._conversationId);

  const {data} = useGetConversationTranscription(_conversationId);

  return (
    <TranscriptionSidebarContainer
      className={cx(isOpen ? classes.open : classes.closed)}
    >
      <StyledCollapseButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <SidebarContent>
          <TitleWrapper>{t('callTranscription')}</TitleWrapper>
          <MessagesContainer>
            {data?.map((message, index) => (
              <MessageWrapper key={index}>
                <MessageSenderWrapper>
                  <MessageSender
                    color={message.role === 'assistant' ? '#349D8E' : '#364FC7'}
                    backgroundColor={
                      message.role === 'assistant' ? '#349D8E1A' : '#364FC71A'
                    }
                  >
                    {message.role === 'user' ? t('agent') : t('customer')}
                  </MessageSender>
                </MessageSenderWrapper>
                <MessageContent>{message.content}</MessageContent>
              </MessageWrapper>
            ))}
          </MessagesContainer>
        </SidebarContent>
      )}
    </TranscriptionSidebarContainer>
  );
};
