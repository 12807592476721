import {createStyles, Drawer} from '@mantine/core';
import styled from 'styled-components';
import {Color} from '../../../../../enums/common';

export const StyledDrawer = styled(Drawer).attrs(() => ({
  styles: {
    title: {
      padding: '.5rem',
      color: '#26145C',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body: {
      padding: '2rem',
    },
  },
}))``;

export const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 85vh;
`;

export const ContentHolder = styled.div`
  width: 100%;
`;

export const MemberDataHolderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  border-top: 1px solid #f6f4fc;
  width: 100%;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #f6f4fc;
`;

export const UserPersonalInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const UserFullNameWrapper = styled.span`
  color: #26145c;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  letter-spacing: 0.15px;
`;

export const UserEmailWrapper = styled.span`
  color: #8f87a8;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
`;

export const UserNameAndEmailDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const TeamNameHolder = styled.div`
  color: #26145c;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const UnassignButtonText = styled.div`
  color: #3c2c6c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
`;

export const UnassignOptionMainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f6f4fc;
  padding: 20px 0 20px 0;
`;

export const TeamWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const CancelWrapper = styled.span`
  color: #26145c;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.3px;
`;

export const TransferButtonTextWrapper = styled.span`
  color: #3c2c6c;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
`;

export const TableHeader = styled.th`
  background-color: #f1f0f4;
  padding: 10px;
  text-align: left;
  color: #26145c !important;
  position: sticky;
  top: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.15px;
  z-index: 1;
`;

export const TableCell = styled.td`
  color: #5c4d8a;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
`;

export const ActionsTableCell = styled.div`
  padding: 10px;
  display: flex;
  justify-content: end;
`;

export const useStyles = createStyles({
  rightSection: {
    pointerEvents: 'none',
    marginRight: '8px',
  },
  root: {
    width: '130px',
  },
  input: {
    border: `1px solid ${Color.PURPLE_150}`,
    fontSize: '13px',
    color: Color.PURPLE_TXT,
    '::placeholder': {
      color: Color.PURPLE_TXT,
      opacity: 1,
    },
  },
});
