import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

// Services
import {Exercise} from 'types/chapters/chapters';
import SlideText from 'components/practices/voicebots/v3/SlideText';
import ChatBotV3 from 'components/practices/voicebots/v3/ChatBotV3';
import {Anchor, Breadcrumbs} from '@mantine/core';
import {BreadcrumbsStyle} from 'styles';
import ChatBotV3Feedback from 'components/practices/voicebots/v3/ChatBotV3Feedback';
import ConversationFeedback from 'components/practices/voicebots/v3/ConversationFeedback';

// Zustand Store
import {useChatBotV3Store} from 'store/ChatBotV3store';
import {useNavigate} from 'react-router-dom';

// Styled Components
const Container = styled.div`
  margin: 20px auto;
`;

export interface ChatBotV3Conversation {
  role: 'assistant' | 'user' | 'system';
  content: string;
}

interface Props {
  userExerciseId: number;
  hideBreadCrumbs?: boolean;
  conversationId: number;
  exercise: Exercise;
  isCourse?: boolean;
  handleAdvance?: ({isQuiz}: {isQuiz: boolean}) => void;
  handleShowingConversationTranscription?: () => void;
}

const ChatBotV3Container = ({
  conversationId = 0,
  exercise,
  isCourse = false,
  handleAdvance = () => null,
  hideBreadCrumbs = true,
  userExerciseId,
  handleShowingConversationTranscription,
}: Props) => {
  const {
    page,
    textMessage,
    _conversationId,
    _setConversationId,
    conversationIds,
    setConversationIds,
    feedbacksHaveBeenGenerated,
    feedbackLoading,
    setUserFeedback,
    handleChatBotCommunication,
    handleStartExercise,
    handleFeedback,
    handleRetry,
    generateFeedback,
    handleEndExercise,
  } = useChatBotV3Store();

  const {t} = useTranslation('Practices');
  const navigate = useNavigate();

  const onUserConversationFeedback = ({value}: {value: boolean}) => {
    setUserFeedback(value);
    generateFeedback({value});
  };

  useEffect(() => {
    handleChatBotCommunication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textMessage]);

  useEffect(() => {
    handleFeedback({isCourse, userExerciseId});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacksHaveBeenGenerated]);

  useEffect(() => {
    _setConversationId(conversationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_conversationId) {
      setConversationIds([...conversationIds, _conversationId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_conversationId]);

  const breadcrumbItems = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.practices'),
      href: '/practices',
    },
    {title: `${exercise?.title}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <Container
      style={{
        width: isCourse ? '100%' : '60%',
      }}
    >
      {!hideBreadCrumbs && (
        <Breadcrumbs mb="sm" separator="→" styles={BreadcrumbsStyle}>
          {breadcrumbItems}
        </Breadcrumbs>
      )}
      {page === 1 && <SlideText exercise={exercise} />}

      {page === 2 && exercise && (
        <ChatBotV3
          exercise={exercise}
          onUserConversationFeedback={onUserConversationFeedback}
        />
      )}

      {page === 3 && (
        <ConversationFeedback
          handleNextButton={() => {
            handleStartExercise(userExerciseId);
          }}
        />
      )}

      {page === 4 && (
        <ChatBotV3Feedback
          handleShowingConversationTranscription={
            handleShowingConversationTranscription
          }
          feedbackLoading={feedbackLoading || !feedbacksHaveBeenGenerated}
          handleEndExercise={() =>
            handleEndExercise({isCourse, handleAdvance, navigate})
          }
          handleRetry={() => handleRetry(userExerciseId)}
        />
      )}
    </Container>
  );
};

export default ChatBotV3Container;
