import {ActionIcon, Table} from '@mantine/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {DepartmentsResponse} from '../../../../api/tanstack-hooks/team-managment/interfaces/DepartmentsResponse';
import {useDepartmentsForOrganization} from '../../../../api/tanstack-hooks/team-managment/useDepartmentsForOrganization';
import {SortingField} from '../../sorting/SortingField';
import {SortingType} from '../../sorting/SortingType';
import {TableWrapper} from '../TableWrapper/TableWrapper';
import {AscendingSortingIconButton} from '../TeamManagementSettings/icon-button-components/AscendingSortingIconButton';
import {DescendingSortingIconButton} from '../TeamManagementSettings/icon-button-components/DescendingSortingIconButton';
import {UnsortedIconButton} from '../TeamManagementSettings/icon-button-components/UnsortedIconButton';
import {TeamMemberOverviewComponent} from '../TeamMemberOverviewComponent/TeamMemberOverviewComponent';
import {TeamOverviewComponentProps} from './interfaces/TeamOverviewComponentProps';

const TableHeader = styled.th<{textAlign?: string}>`
  background-color: #f1f0f4;
  padding: 10px;
  text-align: ${({textAlign}) => textAlign || 'left'} !important;
  color: #26145c !important;
  position: sticky;
  top: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.15px;
`;

const TableCell = styled.td<{textAlign?: string}>`
  padding: 10px !important;
  text-align: ${({textAlign}) => textAlign || 'left'} !important;
  border-bottom: 1px solid #f6f4fc;
  color: #5c4d8a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
`;

const OrganizationNameHolderWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 20px 0 20px 0;
`;

const OrganizationNameHolder = styled.span`
  color: #26145c;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
`;

const CustomTableRowWrapper = styled.tr`
  padding: 10px 0;
  border: none !important;

  &:hover {
    background-color: #f6f4fc;
    cursor: pointer;
  }
`;

export const TeamsOverviewComponent = ({
  organization,
}: TeamOverviewComponentProps) => {
  const {t} = useTranslation('TeamManagement');
  const {data, sortDepartments} = useDepartmentsForOrganization(
    organization.id
  );

  const [sortField, setSortField] = useState<SortingField>(null);
  const [sortState, setSortState] = useState<SortingType>('UNSORTED');
  const [activeDepartment, setActiveDepartment] =
    useState<DepartmentsResponse | null>(null);

  const handleDepartmentSelect = (department: DepartmentsResponse | null) => {
    setActiveDepartment(department);
  };

  const toggleSortState = (field: SortingField) => {
    setSortField(prevField => {
      const isSameField = prevField === field;
      const newState = isSameField
        ? (prevState => {
            switch (prevState) {
              case 'UNSORTED':
                return 'ASC';
              case 'ASC':
                return 'DESC';
              case 'DESC':
                return 'UNSORTED';
              default:
                return 'UNSORTED';
            }
          })(sortState)
        : 'ASC';
      setSortState(newState);

      sortDepartments({
        sortField: field,
        sortType: newState,
      });
      return field;
    });
  };

  const getSortingIcon = (field: SortingField) => {
    if (sortField !== field || sortState === 'UNSORTED')
      return <UnsortedIconButton />;
    return sortState === 'ASC' ? (
      <AscendingSortingIconButton />
    ) : (
      <DescendingSortingIconButton />
    );
  };

  return (
    <>
      {!activeDepartment ? (
        <div>
          <OrganizationNameHolderWrapper>
            <OrganizationNameHolder>{organization.name}</OrganizationNameHolder>
          </OrganizationNameHolderWrapper>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <TableHeader textAlign="left">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      {t('table.teams')}
                      <ActionIcon onClick={() => toggleSortState('name')}>
                        {getSortingIcon('name')}
                      </ActionIcon>
                    </div>
                  </TableHeader>
                  <TableHeader textAlign="right">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      {t('table.numberOfMembers')}
                      <ActionIcon onClick={() => toggleSortState('members')}>
                        {getSortingIcon('members')}
                      </ActionIcon>
                    </div>
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                {data?.map((department, index) => (
                  <CustomTableRowWrapper
                    onClick={() => handleDepartmentSelect(department)}
                    key={index}
                  >
                    <TableCell textAlign="left">{department.name}</TableCell>
                    <TableCell textAlign="right">
                      {department.members}
                    </TableCell>
                  </CustomTableRowWrapper>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </div>
      ) : (
        <TeamMemberOverviewComponent
          teams={data}
          goBackToDepartments={() => handleDepartmentSelect(null)}
          team={activeDepartment}
        />
      )}
    </>
  );
};
