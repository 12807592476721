import {useRef} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {
  startNotification,
  endNotification,
  generateRandomString,
} from '../../../utils/common';
import {updateTeamName} from './fetches/updateTeamName';
import {UpdateDepartmentRequest} from './interfaces/UpdateDepartmentRequest';

export const useUpdateTeamName = () => {
  const {t} = useTranslation('TeamManagement');
  const randomIdRef = useRef<string | null>(null);
  const queryClient = useQueryClient();

  return useMutation(
    ({
      departmentId,
      request,
    }: {
      departmentId: number | undefined;
      request: UpdateDepartmentRequest;
    }) => updateTeamName(departmentId, request),
    {
      onMutate: () => {
        randomIdRef.current = generateRandomString(20);
        startNotification(randomIdRef.current);
      },
      onSuccess: () => {
        if (randomIdRef.current) {
          endNotification(
            randomIdRef.current,
            t('alerts.successTeamNameUpdate'),
            true
          );
        }
        queryClient.invalidateQueries(['departmentsForOrganization']);
      },
      onError: () => {
        if (randomIdRef.current) {
          endNotification(
            randomIdRef.current,
            t('alerts.errorTeamNameUpdate'),
            false
          );
        }
      },
    }
  );
};
