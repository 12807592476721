import {Avatar, Button, Select, Table} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DepartmentsResponse} from '../../../../api/tanstack-hooks/team-managment/interfaces/DepartmentsResponse';
import {useUpdateMemberLevelMutation} from '../../../../api/tanstack-hooks/team-managment/useUpdateMemberLevelMutation';
import {capitalizeFirstLetter} from '../../../../utils/common';
import {AssignMemberToTeamModal} from '../AssignMemberToTeamModal/AssignMemberToTeamModal';
import {TeamIcon} from '../TeamManagementSettings/icon-button-components/TeamIcon';
import {TeamTransferArrows} from '../TeamManagementSettings/icon-button-components/TeamTransferArrows';
import {UnassignActionIconButton} from '../TeamManagementSettings/icon-button-components/UnassignActionIconButton';
import {UnassignTeamMemberModal} from '../UnassignMemberModal/UnassignMemberModal';
import {TeamMemberActionPanelProps} from './interfaces/TeamMemberActionPanelProps';
import {Button as CustomStyledButton} from 'components/button';
import {
  ActionsTableCell,
  CancelWrapper,
  ContentHolder,
  MainWrapper,
  MemberDataHolderWrapper,
  StyledDrawer,
  TableCell,
  TableHeader,
  TeamNameHolder,
  TeamWrapper,
  TransferButtonTextWrapper,
  UnassignButtonText,
  UnassignOptionMainWrapper,
  UserEmailWrapper,
  UserFullNameWrapper,
  UserNameAndEmailDetailsWrapper,
  UserPersonalInfoWrapper,
  useStyles,
} from './styled-helper-components/StyledComponents';

export const TeamMemberActionPanel = ({
  opened,
  close,
  member,
  team,
  teams,
  handleResetSelectMemberFromSidePanel,
}: TeamMemberActionPanelProps) => {
  const {t} = useTranslation('TeamManagement');
  const [selectedLevel, setSelectedLevel] = useState<string>('');
  const [showAvailableTeamsTable, setShowAvailableTeamsTable] =
    useState<boolean>(false);
  const {classes} = useStyles();
  const [
    modalUnassignMemberFromTeamOpened,
    {
      open: openModalUnassignMemberFromTeam,
      close: closeModalUnassignMemberFromTeam,
    },
  ] = useDisclosure(false);
  const [
    modalAssignMemberFromTeamOpened,
    {
      open: openModalAssignMemberFromTeam,
      close: closeModalAssignMemberFromTeam,
    },
  ] = useDisclosure(false);
  const [selectedTeamToAssign, setSelectedTeamToAssign] =
    useState<DepartmentsResponse | null>(null);

  const handleOpenAssignModal = (selectedTeam: DepartmentsResponse | null) => {
    setSelectedTeamToAssign(selectedTeam);
    openModalAssignMemberFromTeam();
  };

  const handleCloseUnassignModal = () => {
    closeModalUnassignMemberFromTeam();
  };

  const handleCloseAssignModal = () => {
    closeModalAssignMemberFromTeam();
  };

  const renderAvatarWithInitials = (fullName: string) => {
    const initials = fullName
      .split(' ')
      .map(word => word[0]?.toUpperCase())
      .join('')
      .slice(0, 2);
    return (
      <Avatar radius="xl" color="blue" size={'lg'}>
        {initials}
      </Avatar>
    );
  };

  const handleShowTeamsTable = () => {
    setShowAvailableTeamsTable(true);
  };

  const handleHideTeamsTable = () => {
    setShowAvailableTeamsTable(false);
  };

  const updateProfile = useUpdateMemberLevelMutation();

  const handleSave = () => {
    if (!member) return;
    updateProfile
      .mutateAsync({
        id: member?.id,
        payload: {expertLevelId: parseInt(selectedLevel)},
      })
      .then(() => {
        setSelectedLevel('');
        handleResetSelectMemberFromSidePanel();
        close();
      });
  };

  const levelOptions = [
    {value: '1', label: t('Dashboard:courseCards.beginner')},
    {value: '2', label: t('Dashboard:courseCards.intermediate')},
    {value: '3', label: t('Dashboard:courseCards.expert')},
  ];

  const handleDrawerClose = () => {
    handleResetSelectMemberFromSidePanel();
    close();
  };

  return (
    <StyledDrawer
      size="lg"
      title={t('drawer.editMembersTitle')}
      position={'right'}
      opened={opened}
      onClose={handleDrawerClose}
    >
      <MainWrapper>
        <ContentHolder>
          <MemberDataHolderWrapper>
            <UserPersonalInfoWrapper>
              <div>{renderAvatarWithInitials(member?.name ?? '')}</div>
              <UserNameAndEmailDetailsWrapper>
                <UserFullNameWrapper>{member?.name}</UserFullNameWrapper>
                <UserEmailWrapper>{member?.email}</UserEmailWrapper>
              </UserNameAndEmailDetailsWrapper>
            </UserPersonalInfoWrapper>
            <div>
              <Select
                data={levelOptions}
                value={
                  selectedLevel ??
                  levelOptions.find(level => level.label === member?.level)
                    ?.label
                }
                onChange={value => setSelectedLevel(value ?? '')}
                placeholder={capitalizeFirstLetter(member?.level ?? '')}
                radius="xl"
                size="xs"
                classNames={{
                  rightSection: classes.rightSection,
                  root: classes.root,
                  input: classes.input,
                }}
              />
            </div>
          </MemberDataHolderWrapper>
          <UnassignOptionMainWrapper>
            <TeamWrapper>
              <TeamIcon />
              <TeamNameHolder>{team?.name}</TeamNameHolder>
            </TeamWrapper>
            <Button
              onClick={openModalUnassignMemberFromTeam}
              variant="default"
              leftIcon={<UnassignActionIconButton />}
            >
              <UnassignButtonText>{t('drawer.unassign')}</UnassignButtonText>
            </Button>
          </UnassignOptionMainWrapper>
          {showAvailableTeamsTable ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Table>
                <thead>
                  <tr>
                    <TableHeader>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        {t('table.teams')}
                      </div>
                    </TableHeader>
                    <TableHeader>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        {t('table.numberOfMembers')}
                      </div>
                    </TableHeader>
                    <TableHeader>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        {t('table.action')}
                      </div>
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {teams?.map((team, index) => (
                    <tr key={index}>
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          {team.name}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          {team.members}
                        </div>
                      </TableCell>
                      <TableCell>
                        <ActionsTableCell>
                          <Button
                            onClick={() => handleOpenAssignModal(team)}
                            variant="default"
                            leftIcon={<TeamTransferArrows />}
                          >
                            <TransferButtonTextWrapper>
                              {t('table.transferHere')}
                            </TransferButtonTextWrapper>
                          </Button>
                        </ActionsTableCell>
                      </TableCell>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button onClick={handleHideTeamsTable} variant="default">
                <CancelWrapper>{t('drawer.cancel')}</CancelWrapper>
              </Button>
            </div>
          ) : (
            <Button
              onClick={handleShowTeamsTable}
              style={{
                width: '100%',
              }}
              variant="default"
              leftIcon={<TeamTransferArrows />}
            >
              <UnassignButtonText>
                {t('drawer.transferToAnotherTeam')}
              </UnassignButtonText>
            </Button>
          )}
        </ContentHolder>
        <CustomStyledButton
          style={{
            width: '100%',
          }}
          onClick={handleSave}
          disabled={updateProfile.isLoading}
        >
          {t('save')}
        </CustomStyledButton>
        <UnassignTeamMemberModal
          closeSideDrawer={close}
          team={team}
          member={member}
          opened={modalUnassignMemberFromTeamOpened}
          onClose={handleCloseUnassignModal}
        />
        <AssignMemberToTeamModal
          onClose={handleCloseAssignModal}
          opened={modalAssignMemberFromTeamOpened}
          closeSideDrawer={close}
          team={selectedTeamToAssign}
          member={member}
        />
      </MainWrapper>
    </StyledDrawer>
  );
};
