import {SortingField} from '../../../../pages/TeamManagement/sorting/SortingField';
import {SortingType} from '../../../../pages/TeamManagement/sorting/SortingType';
import {createAxiosInstance} from '../../../config';
import {TeamMemberResponse} from '../interfaces/TeamMemberResponse';

const axios = createAxiosInstance();

export const getTeamMembersForDepartment = async (
  departmentId: number | undefined,
  sortField?: SortingField,
  sortType?: SortingType
): Promise<TeamMemberResponse[]> => {
  const sortPath =
    sortField && sortType !== 'UNSORTED'
      ? `?sortKey=${sortField},${sortType}`
      : '';
  const response = await axios.get(
    `/pc-department/${departmentId}/members${sortPath}`
  );
  return response.data.data;
};
