import {useQuery} from '@tanstack/react-query';
import {getConversationTranscription} from '../../conversation-transcription/conversationTranscription';

export const useGetConversationTranscription = (conversationId: number) => {
  return useQuery(
    ['conversationTranscription', conversationId],
    () => getConversationTranscription(conversationId),
    {
      enabled: !!conversationId,
    }
  );
};
