import React from 'react';

export const EditMemberIconButton = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9239 2.42204C14.4083 1.93796 15.0651 1.66602 15.75 1.66602C16.4352 1.66602 17.0923 1.9382 17.5768 2.42268C18.0612 2.90717 18.3334 3.56427 18.3334 4.24944C18.3334 4.93441 18.0614 5.59134 17.5772 6.07578C17.577 6.07592 17.5773 6.07565 17.5772 6.07578L16.5237 7.13301C16.4945 7.17611 16.4608 7.21708 16.4226 7.25527C16.3851 7.29273 16.345 7.32587 16.3028 7.3547L10.5903 13.0876C10.4339 13.2446 10.2215 13.3328 10 13.3328H7.5C7.03976 13.3328 6.66667 12.9597 6.66667 12.4994V9.99944C6.66667 9.77791 6.75487 9.5655 6.9118 9.40913L12.6443 3.6971C12.6732 3.6547 12.7065 3.61438 12.7441 3.57676C12.7824 3.53841 12.8236 3.50458 12.8669 3.47526L13.9232 2.42268C13.9235 2.42247 13.9237 2.42225 13.9239 2.42204ZM13.3427 5.35394L8.33333 10.3455V11.6661H9.65395L14.6455 6.65669L13.3427 5.35394ZM15.8219 5.47608L14.5234 4.17753L15.1018 3.60119C15.2737 3.42927 15.5069 3.33268 15.75 3.33268C15.9931 3.33268 16.2263 3.42927 16.3982 3.60119C16.5702 3.77312 16.6668 4.0063 16.6668 4.24944C16.6668 4.49258 16.5702 4.72576 16.3982 4.89768L15.8219 5.47608ZM3.23223 5.73158C3.70107 5.26274 4.33696 4.99935 5 4.99935H5.83333C6.29357 4.99935 6.66667 5.37244 6.66667 5.83268C6.66667 6.29292 6.29357 6.66602 5.83333 6.66602H5C4.77899 6.66602 4.56702 6.75381 4.41074 6.91009C4.25446 7.06637 4.16667 7.27833 4.16667 7.49935V14.9993C4.16667 15.2204 4.25446 15.4323 4.41074 15.5886C4.56702 15.7449 4.77899 15.8327 5 15.8327H12.5C12.721 15.8327 12.933 15.7449 13.0893 15.5886C13.2455 15.4323 13.3333 15.2204 13.3333 14.9993V14.166C13.3333 13.7058 13.7064 13.3327 14.1667 13.3327C14.6269 13.3327 15 13.7058 15 14.166V14.9993C15 15.6624 14.7366 16.2983 14.2678 16.7671C13.7989 17.236 13.163 17.4993 12.5 17.4993H5C4.33696 17.4993 3.70107 17.236 3.23223 16.7671C2.76339 16.2983 2.5 15.6624 2.5 14.9993V7.49935C2.5 6.83631 2.76339 6.20042 3.23223 5.73158Z"
        fill="#8F87A8"
      />
    </svg>
  );
};
