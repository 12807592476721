import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Select,
  Pagination,
  Input,
  MultiSelect,
  Modal,
  Progress,
  Box,
  Text,
  Anchor,
  Avatar,
  Badge,
} from '@mantine/core';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {
  AlertTriangle,
  Search,
  UserExclamation,
  Users,
  Plus,
} from 'tabler-icons-react';

// hooks
import {useGetDepartmentNames} from 'api/tanstack-hooks/department/useGetDepartmentNames';
import {useTeamOverview} from 'api/tanstack-hooks/department/useTeamOverview';
import {useDashboardMetrics} from 'api/tanstack-hooks/department/useDashboardMetrics';
import {useGetProfileInfo} from 'api/tanstack-hooks/users';
import useLocalAndUrlStore from 'store/useLocalAndUrlStore';

// Utils
import {useCurrentWeeksOfYear} from 'utils/common/datesUtil';
import {capitalizeFirstLetter} from 'utils/common';

// Types
import {TeamEmployee} from 'types/users/users';
import {Color} from 'enums/common';

// Components
import {AssignCourses} from './_AssignCourses';
import {Column, DynamicTable} from 'components/table/DynamicTable';
import {MetricCard} from 'components/common/Cards/MetricCard';
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import {format} from 'date-fns';

// Style
const Header = styled.div`
  font-family: 'Manrope', sans-serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

const Title = styled.div`
  font-family: 'Manrope', sans-serif;
  color: #fa5f4f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const SubTitle = styled.div`
  font-family: 'Manrope', sans-serif;
  color: ${Color.DARK};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TableTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: ${Color.DARK};
`;

const InputsGroup = styled.div`
  min-width: max-content;
  display: flex;
  gap: 8px;
  align-self: flex-end;

  & input {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.DARK};
  }
`;

const ProgresBarLabel = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  gap: 8px;
`;

const MetricsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;

  & > div {
    flex: 1;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  padding: 12px;
  background-color: white;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 6px;

  input {
    height: 26px;
    width: 120px;
    min-height: unset;
    color: ${Color.DARK};
  }
`;

export const ManagerDashboard = () => {
  // State
  const [sortKey, setSortKey] = useState<string>('');
  const [userId, setUserId] = useState<number | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  // Hooks
  const {
    departmentId,
    page,
    size,
    from,
    to,
    levelIds,
    searchKey,
    setDepartmentId,
    setPage,
    setSize,
    setFrom,
    setTo,
    setLevelIds,
    setSearchKey,
  } = useLocalAndUrlStore();

  const navigate = useNavigate();
  const {t} = useTranslation('Dashboard');

  const {getCurrentWeeksOfYear} = useCurrentWeeksOfYear(t);
  const weeks = getCurrentWeeksOfYear();

  // Services
  const {data: departmentNamesData} = useGetDepartmentNames();
  const {data: dashboardMetricsData} = useDashboardMetrics({
    departmentId: Number(departmentId),
    from: from ?? undefined,
    to: to ?? undefined,
  });
  const {data: userProfile} = useGetProfileInfo();
  const {data: teamOverviewData, isLoading} = useTeamOverview({
    departmentId: Number(departmentId),
    page,
    size,
    sortKey,
    from: from ?? undefined,
    to: to ?? undefined,
    levelIds: levelIds.map(id => Number(+id)).join(','),
    searchKey: searchKey,
  });

  // Handlers
  let timerId: ReturnType<typeof setTimeout>;

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      setSearchKey(value);
    }, 700);
  }

  // Calculations
  const departmentSelectOptions =
    departmentNamesData?.data.map(({name, departmentId}) => ({
      label: name,
      value: departmentId.toString(),
    })) || [];

  const levelOptions = [
    {value: '1', label: t('courseCards.beginner')},
    {value: '2', label: t('courseCards.intermediate')},
    {value: '3', label: t('courseCards.expert')},
  ];

  const {pendingMembers, totalMembers, underPerformingMembers} =
    dashboardMetricsData?.data || {};

  const {fullName, email} = userProfile?.data || {};

  const columns: Column<TeamEmployee>[] = [
    {
      slug: '',
      title: {name: '', sortable: false},
      key: '',
      render: ({avatar, id}: TeamEmployee) => (
        <Avatar
          onClick={() => {
            navigate(`userProfile/userId/${id}`, {
              state: {departmentId},
            });
          }}
          src={avatar}
          size="md"
          radius="lg"
          alt="User profile image"
        />
      ),
    },
    {
      slug: 'name',
      title: {name: `${t('table.teamMember')}`, sortable: true},
      key: 'name',
      render: ({name, id}: TeamEmployee) => (
        <Anchor
          onClick={() => {
            navigate(`userProfile/userId/${id}`, {
              state: {departmentId},
            });
          }}
        >
          {name ?? '-'}
        </Anchor>
      ),
    },
    {
      slug: 'email',
      title: {name: `${t('table.email')}`, sortable: true},
      key: 'email',
    },
    {
      slug: 'awards',
      title: {name: `${t('awards')}`, sortable: true},
      key: 'awards',
      render: ({awards}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center'}}>🏆{awards}</Box>
      ),
    },
    {
      slug: 'points',
      title: {name: `${t('table.points')}`, sortable: true},
      key: 'points',
    },
    {
      slug: 'level',
      title: {name: `${t('table.level')}`, sortable: true},
      key: 'level',
      render: ({level}: TeamEmployee) => capitalizeFirstLetter(level),
    },
    {
      slug: 'pointsOverview',
      title: {name: `${t('table.pointsForNextTrophy')}`, sortable: true},
      key: 'weeklyPoints', //sorting param sent in the payload to BE
      render: ({weeklyPoints, trophyPoints}: TeamEmployee) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <ProgresBarLabel>{weeklyPoints}</ProgresBarLabel>
            <ProgresBarLabel>{`${trophyPoints} ${t(
              'table.progressBarPoints'
            )}`}</ProgresBarLabel>
          </Box>
          <Box>
            <Progress
              value={(weeklyPoints / trophyPoints) * 100}
              color={Color.GREEN_050}
            />
          </Box>
        </Box>
      ),
    },
    {
      slug: 'feedbackReport',
      title: {name: `${t('table.feedbackReport')}`, sortable: false},
      key: 'feedbackReport',
      render: ({name, id}: TeamEmployee) => (
        <Anchor
          onClick={() => navigate(`feedback/userId/${id}`, {state: name})}
          component="button"
          type="button"
        >
          {t('table.seeFeedback')}
        </Anchor>
      ),
    },
    {
      slug: 'state',
      title: {name: `${t('table.status')}`, sortable: true},
      key: 'state',
      render: ({state}: TeamEmployee) => (
        <Badge
          style={{
            color:
              state === 'PENDING' ? `${Color.RED_800}` : `${Color.INDIGO_800}`,
            backgroundColor:
              state === 'PENDING' ? `${Color.RED_100}` : `${Color.INDIGO_100}`,
          }}
        >
          {state === 'PENDING' ? t('pending') : t('activ')}
        </Badge>
      ),
    },
    {
      slug: 'lastActivity',
      title: {name: `${t('table.lastActivity')}`, sortable: true},
      key: 'lastActivity',
      render: ({lastActivity, state}: TeamEmployee) => (
        <>
          {state === 'PENDING'
            ? '-'
            : (lastActivity &&
                format(new Date(lastActivity), 'yyy-MM-dd HH:mm')) ||
              '-'}
        </>
      ),
    },
    {
      slug: 'assignedCourses',
      title: {name: `${t('table.assignedCourses')}`, sortable: true},
      key: 'assignedCourses',
    },
    {
      slug: '',
      title: {name: '', sortable: true},
      key: '',
      render: ({name, id}: TeamEmployee) => (
        <Plus
          onClick={() => {
            setUserId(id);
            setOpenModal(true);
            setUserName(name);
          }}
          style={{cursor: 'pointer'}}
          size={16}
          color={'var(--mantine-color-violet-8)'}
        />
      ),
    },
  ];

  const paginationOptions = [
    {value: '15', label: '15 rows/page'},
    {value: '30', label: '30 rows/page'},
    {value: '45', label: '45 rows/page'},
    {value: '60', label: '60 rows/page'},
    {value: '75', label: '75 rows/page'},
    {value: '100', label: '100 rows/page'},
  ];

  useEffect(() => {
    if (departmentNamesData?.data?.length) {
      setDepartmentId(departmentNamesData?.data[0]?.departmentId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentNamesData?.data]);

  return (
    <BasePage>
      <MainContainer>
        <Header>
          <TitleWrapper>
            <Title>{`${t('welcomeBanner.hi')}, ${
              fullName ?? email ?? ''
            }!`}</Title>
            <SubTitle>{t('welcomeBanner.subTitle')}</SubTitle>
          </TitleWrapper>

          <InputsGroup>
            <Select
              placeholder={t('inputs.team')}
              data={departmentSelectOptions}
              value={departmentId}
              onChange={setDepartmentId}
              styles={{
                item: {
                  '&[data-selected], &[data-hovered]': {
                    backgroundColor: Color.GRAY_50,
                    color: Color.DARK,
                  },
                  '&[data-selected]': {
                    '&:hover': {
                      backgroundColor: Color.GRAY_50, // Ensure selected item has the same hover color
                    },
                  },
                },
              }}
            />
            <Select
              placeholder="Choose a week"
              data={weeks.map(week => ({
                value: JSON.stringify(week.value),
                label: week.label,
              }))}
              value={JSON.stringify({from, to})}
              defaultValue={JSON.stringify(weeks[weeks.length - 1].value)}
              onChange={value => {
                if (value) {
                  const week = JSON.parse(value);
                  setFrom(week.from);
                  setTo(week.to);
                }
              }}
              dropdownPosition="bottom"
              withinPortal
              shadow="md"
              styles={{
                item: {
                  '&[data-selected], &[data-hovered]': {
                    backgroundColor: Color.GRAY_50,
                    color: Color.DARK,
                  },
                  '&[data-selected]': {
                    '&:hover': {
                      backgroundColor: Color.GRAY_50,
                    },
                  },
                },
              }}
            />
          </InputsGroup>
        </Header>

        <MetricsWrapper>
          <MetricCard
            title={t('metricCards.totalMembers')}
            icon={<Users />}
            metricValue={totalMembers}
          />
          <MetricCard
            title={t('metricCards.underperforming')}
            icon={<AlertTriangle />}
            metricValue={`${underPerformingMembers ?? '-'}/${
              totalMembers ?? '-'
            }`}
          />
          <MetricCard
            title={t('metricCards.pendingInvitations')}
            icon={<UserExclamation />}
            metricValue={
              <>
                <span style={{color: 'red'}}>{pendingMembers ?? '-'}</span>/
                {totalMembers ?? '-'}
              </>
            }
          />
        </MetricsWrapper>

        <TableHeader>
          <TableTitle>{t('table.title')}</TableTitle>

          <InputsGroup>
            <Input
              defaultValue={searchKey}
              icon={<Search size={20} />}
              placeholder="Search"
              onChange={handleSearch}
            />
            <MultiSelect
              data={levelOptions}
              value={levelIds}
              placeholder={t('level')}
              onChange={e => setLevelIds(e)}
            />
          </InputsGroup>
        </TableHeader>
        <DynamicTable
          isLoading={departmentId ? isLoading : false}
          data={teamOverviewData?.data?.elements || []}
          onSort={({key, order}) =>
            setSortKey(`${String(key)},${order.toUpperCase()}`)
          }
          columns={columns}
          rowsPerPage={size}
          height="fixed"
        />
        <PaginationWrapper>
          <Pagination
            value={page}
            onChange={page => setPage(page)}
            total={Math.ceil((teamOverviewData?.data?.total || 0) / size)}
            size="sm"
            withControls={false}
            withEdges
            position="right"
            styles={{
              control: {
                '&[data-active]': {
                  backgroundColor: '#26145C',
                  color: 'white',
                },
              },
            }}
          />
          <Select
            size="xs"
            data={paginationOptions}
            value={size.toString()}
            onChange={size => setSize(size ? +size : 15)}
            width={30}
          />
        </PaginationWrapper>

        <Modal
          opened={openModal}
          onClose={() => {
            setUserId(null);
            setUserName(null);
            setOpenModal(false);
          }}
          padding={24}
          size="lg"
          styles={{
            header: {
              backgroundColor: 'unset',
            },
          }}
        >
          {userId && userName && (
            <AssignCourses
              userName={userName}
              userId={userId}
              onClose={setOpenModal}
            />
          )}
        </Modal>
      </MainContainer>
    </BasePage>
  );
};
