import styled from 'styled-components';
import {BulletPointProps} from './interfaces/BulletPointProps';

export const BulletPoint = styled.span<BulletPointProps>`
  display: inline-block;
  width: ${({width = '10px'}) => width};
  height: ${({height = '10px'}) => height};
  background-color: ${({backgroundColor = 'black'}) => backgroundColor};
  border-radius: 50%;
  margin-right: 4px;
`;
