import styled from 'styled-components';

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  gap: 0;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(77, 60, 130, 0.2);
`;
