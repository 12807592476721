import {Box, Card, Progress, Text} from '@mantine/core';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Color} from '../../../../enums/common';
import {CustomTrophiesBadgeComponent} from '../CustomTrophiesBadgeComponent/CustomTrophiesBadgeComponent';
import {WeeklyPointsComponentInterface} from './interfaces/WeeklyPointsComponentInterface';

const Title = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${Color.DARK};
`;

const BigValueWrapper = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: ${Color.DARK};
`;

const ProgressBarLabel = styled.div<{color?: string}>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({color = Color.DARK}) => color};
`;

const ParentWrapper = styled.div`
  display: flex;
`;

const LeftColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5px;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
`;

const ProgressBar: FC<{value: number; color: string; flex: string}> = ({
  value,
  color,
  flex,
}) => (
  <Progress
    value={value}
    color={color}
    sx={{flex: flex}}
    style={{width: '100%'}}
  />
);

export const WeeklyPointsComponent = ({
  trophyPoints,
  weeklyPoints,
}: WeeklyPointsComponentInterface) => {
  const {t} = useTranslation('Dashboard');
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        gap: '1.5rem',
        boxShadow: '0px 2px 4px 0px rgba(77, 60, 130, 0.20)',
      }}
    >
      <Box>
        <Title>{t('metricCards.totalPointsThisWeek')}</Title>
      </Box>
      <ParentWrapper>
        <LeftColumnWrapper>
          <BigValueWrapper>{weeklyPoints}</BigValueWrapper>
          <ProgressBarLabel color="#8F87A8">{t('actual')}</ProgressBarLabel>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <ProgressBarLabel>{trophyPoints ?? 50}</ProgressBarLabel>
          <ProgressBarWrapper>
            <ProgressBar
              value={((weeklyPoints ?? 0) / (trophyPoints ?? 1)) * 100}
              color={Color.GREEN_050}
              flex={'1'}
            />
          </ProgressBarWrapper>
          <ProgressBarLabel color="#8F87A8">
            {t('recommended')}
          </ProgressBarLabel>
        </RightColumnWrapper>
      </ParentWrapper>
      <CustomTrophiesBadgeComponent
        displayText={t('metricCards.weeklyNeededPoints')}
      />
    </Card>
  );
};
