import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
} from 'victory';

export interface PointsChartProps {
  day: string;
  trophyPoints: number;
  voluntaryPoints: number;
}

export const PointsChart: FC<{
  pointsDetails: PointsChartProps[];
  maxPointsValue: number;
}> = ({pointsDetails, maxPointsValue}) => {
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const {t} = useTranslation('Dashboard');
  const translatedWeekdays = weekdays.map(day => t(`charts.${day}`));

  const roundToNearest500 = (value: number) => {
    return Math.ceil(value / 500) * 500;
  };

  const calculateIncrement = (roundedMax: number) => {
    return roundedMax / 10;
  };
  const roundedMax = roundToNearest500(maxPointsValue);

  const increment = calculateIncrement(roundedMax);

  const fixedPointValues = [];
  for (let i = 0; i <= roundedMax; i += increment) {
    fixedPointValues.push(i);
  }

  return (
    <VictoryChart
      domainPadding={{x: [15, 0]}}
      style={{
        parent: {
          borderRadius: '4px',
          maxHeight: '550px',
          zIndex: 0,
        },
      }}
      containerComponent={<VictoryContainer />}
    >
      <VictoryAxis
        tickValues={weekdays}
        tickFormat={x => translatedWeekdays[weekdays.indexOf(x)]}
        style={{
          axis: {stroke: '#E5E5EF'},
          ticks: {size: 5, stroke: 'transparent'},
          tickLabels: {
            fontSize: 10,
            fill: '#A29FC4',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            padding: 0,
          },
        }}
      />

      <VictoryAxis
        dependentAxis
        tickValues={fixedPointValues}
        tickFormat={y => `${y} ${t('charts.points')}`}
        style={{
          axis: {stroke: 'transparent'},
          ticks: {size: 5, stroke: 'transparent'},
          tickLabels: {
            fontSize: 8,
            fill: '#A29FC4',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            padding: fixedPointValues.length / 20,
          },
        }}
      />

      {fixedPointValues.map(fixedPoints => (
        <VictoryLine
          key={fixedPoints}
          data={[
            {x: 'Mon', y: fixedPoints},
            {x: 'Sun', y: fixedPoints},
          ]}
          style={{
            data: {
              stroke: '#E5E5EF',
              strokeWidth: 1,
              strokeDasharray: '3,3',
            },
          }}
        />
      ))}

      <VictoryGroup>
        <VictoryBar
          data={pointsDetails?.map(({day, trophyPoints}) => ({
            x: day,
            y: trophyPoints,
          }))}
          style={{data: {fill: '#349d8e'}}}
          cornerRadius={{
            bottomLeft: 4,
            bottomRight: 4,
            topLeft: 4,
            topRight: 4,
          }}
          barWidth={35}
        />
        <VictoryBar
          data={pointsDetails?.map(({day, voluntaryPoints}) => ({
            x: day,
            y: voluntaryPoints,
          }))}
          style={{data: {fill: '#bb6bd9'}}}
          cornerRadius={{
            bottomLeft: 4,
            bottomRight: 4,
            topLeft: 4,
            topRight: 4,
          }}
        />
      </VictoryGroup>
    </VictoryChart>
  );
};
