import {Modal} from '@mantine/core';
import React from 'react';
import {StyledModalProps} from './interfaces/StyledModalProps';

export const StyledModal = ({
  opened,
  onClose,
  title,
  size,
  children,
}: StyledModalProps) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      styles={{
        title: {
          color: '#26145c',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '24px',
          letterSpacing: '0.15px',
        },
      }}
      title={title}
      size={size}
    >
      {children}
    </Modal>
  );
};
