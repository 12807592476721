// src/api/conversations/conversations.ts

import {createAxiosInstance} from 'api/config';
import {Conversations, ServerTime} from '../../enums/conversations/urls';

import {
  ConversationInitialize,
  ConversationInitializePayload,
  ConversationMessage,
  ConversationMessagePayload,
  ConversationPayload,
} from '../../types/conversations/conversations';
import {Conversation} from '../../types/practices';
import {configuration} from 'config';

/**
 * Create an Axios instance with the baseConversationsUrl from configuration.
 */
const axiosInstance = createAxiosInstance(configuration().baseConversationsUrl);

/**
 * Function to retrieve a conversation based on the provided payload.
 * @param payload - The conversation payload containing necessary data.
 * @returns The conversation data from the API.
 */
export const getConversation = async (
  payload: ConversationPayload
): Promise<Conversation> => {
  try {
    const {data} = await axiosInstance.post<Conversation>(
      `/${Conversations.CONVERSATIONS}`,
      payload
    );
    return data;
  } catch (error) {
    console.error('Error fetching conversation:', error);
    throw error;
  }
};

/**
 * Function to initialize a conversation.
 * @param conversation - The payload for initializing the conversation.
 * @param conversation_id - The unique identifier for the conversation.
 * @returns The initialized conversation data from the API.
 */
export const conversationInitialize = async (
  conversation: ConversationInitializePayload,
  conversation_id: string
): Promise<ConversationInitialize> => {
  try {
    const {data} = await axiosInstance.post<ConversationInitialize>(
      `/${Conversations.CONVERSATION_INITIALIZE}/${conversation_id}`,
      conversation
    );
    return data;
  } catch (error) {
    console.error('Error initializing conversation:', error);
    throw error;
  }
};

/**
 * Function to send a message in a conversation.
 * @param conversation - The message payload.
 * @param conversation_id - The unique identifier for the conversation.
 * @returns The message data from the API.
 */
export const conversationMessage = async (
  conversation: ConversationMessagePayload,
  conversation_id: string
): Promise<ConversationMessage> => {
  try {
    const {data} = await axiosInstance.post<ConversationMessage>(
      `/${Conversations.RASA_CONVERSATION}/${conversation_id}`,
      conversation
    );
    return data;
  } catch (error) {
    console.error('Error sending conversation message:', error);
    throw error;
  }
};

/**
 * Function to retrieve server time.
 * @returns The server time data from the API.
 */
export const getServerTime = async (): Promise<ServerTime> => {
  try {
    const {data} = await axiosInstance.get<ServerTime>(
      `/${ServerTime.GET_SERVER_TIME}`
    );
    return data;
  } catch (error) {
    console.error('Error fetching server time:', error);
    throw error;
  }
};
