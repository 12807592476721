import React from 'react';

export const TeamTransferArrows = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 1.5C5.97889 1.5 6.33333 1.83579 6.33333 2.25V13.9393L7.35687 12.9697C7.66604 12.6768 8.16729 12.6768 8.47646 12.9697C8.78562 13.2626 8.78562 13.7374 8.47646 14.0303L6.10146 16.2803C5.79229 16.5732 5.29104 16.5732 4.98187 16.2803L2.60687 14.0303C2.29771 13.7374 2.29771 13.2626 2.60687 12.9697C2.91604 12.6768 3.41729 12.6768 3.72646 12.9697L4.75 13.9393V2.25C4.75 1.83579 5.10444 1.5 5.54167 1.5ZM12.8985 1.71967C13.2077 1.42678 13.709 1.42678 14.0181 1.71967L16.3931 3.96967C16.7023 4.26256 16.7023 4.73744 16.3931 5.03033C16.084 5.32322 15.5827 5.32322 15.2735 5.03033L14.25 4.06066V6.75C14.25 7.16421 13.8956 7.5 13.4583 7.5C13.0211 7.5 12.6667 7.16421 12.6667 6.75V4.06066L11.6431 5.03033C11.334 5.32322 10.8327 5.32322 10.5235 5.03033C10.2144 4.73744 10.2144 4.26256 10.5235 3.96967L12.8985 1.71967ZM14.25 9.75V11.25C14.25 11.6642 13.8956 12 13.4583 12C13.0211 12 12.6667 11.6642 12.6667 11.25V9.75C12.6667 9.33579 13.0211 9 13.4583 9C13.8956 9 14.25 9.33579 14.25 9.75ZM14.25 14.25V15.75C14.25 16.1642 13.8956 16.5 13.4583 16.5C13.0211 16.5 12.6667 16.1642 12.6667 15.75V14.25C12.6667 13.8358 13.0211 13.5 13.4583 13.5C13.8956 13.5 14.25 13.8358 14.25 14.25Z"
        fill="#8F87A8"
      />
    </svg>
  );
};
