import {showNotification} from '@mantine/notifications';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {UserProfilePayload} from '../../../types/users/users';
import {updateUserProfile} from '../../user/user';

export const useUpdateMemberLevelMutation = () => {
  const queryClient = useQueryClient();

  const {t} = useTranslation('TeamManagement');

  return useMutation(
    ({id, payload}: {id: number; payload: UserProfilePayload}) =>
      updateUserProfile(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['teamMembers']);
        showNotification({
          title: t('drawer.success'),
          message: t('alerts.successChangeLevel'),
          color: 'green',
        });
      },
      onError: () => {
        showNotification({
          title: t('drawer.error'),
          message: t('alerts.errorChangeLevel'),
          color: 'red',
        });
      },
    }
  );
};
