import {createAxiosInstance} from '../../../config';
import {AssignUserToDepartmentRequest} from '../interfaces/AssignUserToDepartmentRequest';

const axios = createAxiosInstance();

export const assignTeamMemberFromTeam = async (
  assignUserToDepartmentPayload: AssignUserToDepartmentRequest
) => {
  const response = await axios.post(
    `/pc-department/assign-user`,
    assignUserToDepartmentPayload
  );
  return response.data.data;
};
