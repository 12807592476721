import React, {useState} from 'react';
import {
  Space,
  Avatar,
  Select,
  Pagination,
  Box,
  Progress,
  Button,
  ActionIcon,
} from '@mantine/core';

// Components
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import FeedbackCard from 'components/common/FeedbackCard/FeedbackCard';
import {UserMetrics} from 'pages/UserProfile/_UserMetrics';
import {
  PointsChart,
  PointsChartProps,
} from 'components/dashboard/common/PointsChart';
import {AllSummaryCourses} from 'components/courses/user/SummaryCourse/AllSummaryCourses';
import {Column, DynamicTable} from 'components/table/DynamicTable';

// Types
import {Color} from 'enums/common';
import {TeamEmployee} from 'types/users/users';

// Utils
import {useCurrentWeeksOfYear} from 'utils/common/datesUtil';
import {capitalizeFirstLetter} from 'utils/common';

// Hooks
import {useUserDashboard} from 'api/tanstack-hooks/users/useUserDashboard';
import {useUserTeamOverview} from 'api/tanstack-hooks/department/useUserTeamOverview';
import {useTranslation} from 'react-i18next';
import {CustomTrophiesBadgeComponent} from '../UserProfile/components/CustomTrophiesBadgeComponent/CustomTrophiesBadgeComponent';
import {ChartHeader} from './components/ChartHeader/ChartHeader';
import {ChartTitle} from './components/ChartTitle/ChartTitle';
import {Header} from './components/Header/Header';
import {TableTitle} from './components/TableTitle/TableTitle';
import {Title} from './components/Title/Title';
import {ArrowLeftIcon} from './components/ChartTitleAndWeekSelectionWrapper/icon-components/ArrowLeftIcon';
import {ArrowRightIcon} from './components/ChartTitleAndWeekSelectionWrapper/icon-components/ArrowRightIcon';
import {StyledWelcome} from './components/StyledWelcome/StyledWelcome';
import {SectionWrapper} from './components/SectionWrapper/SectionWrapper';
import {ChartWrapper} from './components/ChartWrapper/ChartWrapper';
import {Subtitle} from './components/Subtitle/Subtitle';
import {ChartFooter} from './components/ChartFooter/ChartFooter';
import {BulletPoint} from './components/BulletPoint/BulletPoint';
import {ProgressBarLabel} from './components/ProgressBarLabel/ProgressBarLabel';
import {PaginationWrapper} from './components/PaginationWrapper/PaginationWrapper';
import {ChartTitleAndWeekSelectionWrapper} from './components/ChartTitleAndWeekSelectionWrapper/ChartTitleAndWeekSelectionWrapper';

export const UserDashboard = () => {
  // Hooks
  const {t} = useTranslation('Dashboard');
  const {getCurrentWeeksOfYear} = useCurrentWeeksOfYear(t);
  const weeks = getCurrentWeeksOfYear();
  // Initialize state
  const initialWeek = weeks[weeks.length - 1].value;

  // State
  const [sortKey, setSortKey] = useState<string>('');
  const [{from, to}, setSelectedWeek] = useState(initialWeek);
  const [page, setPage] = useState<number>(1);
  const [size] = useState<number>(5);

  const handleWeekChange = (direction: number) => {
    const currentWeekIndex = weeks.findIndex(
      week => week.value.from === from && week.value.to === to
    );

    if (currentWeekIndex === -1) return;

    const newWeekIndex = currentWeekIndex + direction;

    if (newWeekIndex >= 0 && newWeekIndex < weeks.length) {
      const newWeek = weeks[newWeekIndex].value;
      setSelectedWeek(newWeek); // Update the selected week range
    }
  };

  const {data: userTeamOverviewData, isLoading} = useUserTeamOverview({
    page,
    size,
    from,
    to,
    sortKey,
  });

  const {data: userProfile} = useUserDashboard({
    from,
    to,
  });

  const {
    assignedCourses,
    totalAssignedCourses,
    completedCourses,
    points,
    name,
    email,
    awards,
    trophyPoints,
    pointsPerDay,
    weeklyPoints,
    hasWeeklyTrophy,
  } = userProfile?.data || {};

  const maxVoluntaryPoints = Math.max(
    ...Object.values(pointsPerDay ?? {}).map(day => day.voluntaryPoints)
  );

  const leftScalePoints = maxVoluntaryPoints > 50 ? maxVoluntaryPoints : 50;

  // Table columns renderers
  const columns: Column<TeamEmployee>[] = [
    {
      slug: 'name',
      title: {name: `${t('table.teamMember')}`, sortable: true},
      key: 'name',
      render: ({name, avatar}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Avatar src={avatar} size="md" radius="lg" alt="User profile image" />
          {name}
        </Box>
      ),
    },
    {
      slug: 'awards',
      title: {name: `${t('awards')}`, sortable: true},
      key: 'awards',
      render: ({awards}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center'}}>🏆{awards}</Box>
      ),
    },
    {
      slug: 'pointsOverview',
      title: {name: `${t('table.pointsForNextTrophy')}`, sortable: true},
      key: 'pointsOverview',
      render: ({weeklyPoints, trophyPoints}: TeamEmployee) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <ProgressBarLabel>{weeklyPoints}</ProgressBarLabel>
            <ProgressBarLabel>{`${trophyPoints} ${t(
              'table.progressBarPoints'
            )}`}</ProgressBarLabel>
          </Box>
          <Box>
            <Progress
              value={(weeklyPoints / trophyPoints) * 100}
              color={Color.GREEN_050}
            />
          </Box>
        </Box>
      ),
    },
  ];

  // Time details
  const timeDetails: PointsChartProps[] = Object.entries(
    userProfile?.data?.pointsPerDay || {}
  )?.map(([day, {trophyPoints, voluntaryPoints}]) => ({
    day: capitalizeFirstLetter(day.substring(0, 3)),
    trophyPoints: trophyPoints,
    voluntaryPoints: voluntaryPoints,
  }));

  return (
    <BasePage>
      <StyledWelcome />
      <MainContainer
        style={{
          marginTop: '-120px',
        }}
      >
        <Header>
          <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <Avatar variant="filled" size="xl" color="blue" radius="50%">
              {name
                ?.split(' ')
                .map(n => n[0])
                .join('')
                .toUpperCase() ?? ''}
            </Avatar>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.7rem',
                justifyContent: 'center',
              }}
            >
              <Title>{`${t('welcomeBanner.bannerTitle')}, ${
                name ?? email ?? ''
              }!`}</Title>
              <Subtitle>{`${t('endUserSubtitle')}`}</Subtitle>
            </div>
          </div>
        </Header>
        <SectionWrapper>
          <UserMetrics
            assignedCoursesLength={totalAssignedCourses || 0}
            completedCourses={completedCourses}
            points={points}
            awards={awards}
            weeklyPoints={weeklyPoints}
            trophyPoints={trophyPoints}
          />
          <ChartWrapper>
            <ChartTitleAndWeekSelectionWrapper>
              <ChartHeader>
                <ChartTitle>{`${t('UserProfile:chart.title')}`}</ChartTitle>
              </ChartHeader>
              <Button.Group style={{display: 'flex', gap: 2}}>
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(-1)}
                  className="left"
                  size={37}
                  variant="default"
                >
                  <ArrowLeftIcon />
                </ActionIcon>
                <Select
                  style={{
                    zIndex: 999,
                  }}
                  placeholder="Choose a week"
                  data={weeks.map(week => ({
                    value: JSON.stringify(week.value),
                    label: week.label,
                  }))}
                  value={JSON.stringify({from, to})}
                  onChange={value => setSelectedWeek(JSON.parse(String(value)))}
                  dropdownPosition="bottom"
                  withinPortal
                  styles={{
                    wrapper: {
                      alignSelf: 'flex-end',
                    },
                    item: {
                      '&[data-selected], &[data-hovered]': {
                        backgroundColor: Color.GRAY_50,
                        color: Color.DARK,
                      },
                      '&[data-selected]': {
                        '&:hover': {
                          backgroundColor: Color.GRAY_50,
                        },
                      },
                    },
                  }}
                />
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(1)}
                  className="right"
                  size={37}
                  variant="default"
                >
                  <ArrowRightIcon />
                </ActionIcon>
              </Button.Group>
            </ChartTitleAndWeekSelectionWrapper>
            <div
              style={{
                width: '100%',
                marginRight: '-80px',
                marginTop: '-25px',
              }}
            >
              <PointsChart
                maxPointsValue={leftScalePoints}
                pointsDetails={timeDetails}
              />
            </div>
            <ChartFooter>
              <div style={{display: 'flex', gap: '1rem'}}>
                <div>
                  <BulletPoint backgroundColor={Color.GREEN_050} />{' '}
                  <span>{`${t('UserProfile:chart.trophyPoints')}`}</span>
                </div>
                <div>
                  <BulletPoint backgroundColor={Color.PURPLE_TXT} />{' '}
                  <span> {`${t('UserProfile:chart.voluntaryPoints')}`}</span>
                </div>
              </div>
              <div>
                {hasWeeklyTrophy && (
                  <CustomTrophiesBadgeComponent
                    displayText={`1 ${t('charts.numberOfTrophies')}`}
                  />
                )}
              </div>
            </ChartFooter>
          </ChartWrapper>
        </SectionWrapper>

        <FeedbackCard
          imageSrc={'/images/noFeedback.png'}
          backgroundColor="LIGHT_GREEN"
          buttonText={`${t('goToTheFeedbacks')}`}
          title={`${t('endUserFeedbackCardTitle')}`}
          to={`/dashboard/feedback/userId/${userProfile?.data.id || ''}`}
        />

        <TableTitle>{t('table.title')}</TableTitle>

        <DynamicTable
          isLoading={isLoading}
          data={userTeamOverviewData?.data?.elements || []}
          columns={columns}
          rowsPerPage={size}
          onSort={({key, order}) =>
            setSortKey(`${String(key)},${order.toUpperCase()}`)
          }
        />
        <PaginationWrapper>
          <Pagination
            value={page}
            onChange={number => setPage(number)}
            total={Math.ceil((userTeamOverviewData?.data?.total || 0) / size)}
            size="sm"
            siblings={1}
            withControls={false}
            withEdges
            position="right"
            styles={{
              control: {
                '&[data-active]': {
                  backgroundColor: '#26145C',
                  color: 'white',
                },
              },
            }}
          />
        </PaginationWrapper>
        <AllSummaryCourses courses={assignedCourses || []} hasButton />
        <Space h={50} />
      </MainContainer>
    </BasePage>
  );
};
